export const midasSpaces = {
  spaces: [
    {
      id: 'Autosar_application_layer_R22',
      name: {
        en: 'Application Layer',
      },
      shortDescription: {
        en: 'Application Layer refers to the software layer responsible for implementing the specific functionalities and behaviors of an application. It is one of the layers in the software architecture of an automotive system, typically built on top of lower level layers',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_communication_driver_R22',
      name: {
        en: 'Communication Driver',
      },
      shortDescription: {
        en: 'communication driver is a software component that provides a standardized interface for communication between different modules or devices within a system. It acts as a bridge between the hardware and the software layers, allowing applications to send and receive data over a communication protocol.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_communication_hardware_abstraction_R22',
      name: {
        en: 'Communication Hardware Abstraction',
      },
      shortDescription: {
        en: 'communication hardware abstraction refers to a layer in the AUTOSAR (AUTomotive Open System ARchitecture) software architecture that provides a standardized interface between the upper layers of software and the underlying communication hardware.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_communication_services_R22',
      name: {
        en: 'Communication Services',
      },
      shortDescription: {
        en: "communication Services in the automotive domain refer to a set of functions and operations that enable the exchange of data between different components or modules within a vehicle's electronic system. These services facilitate the transmission and reception of signals or signal groups, allowing for communication between various control units or electronic control modules (ECMs).",
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_crypto_hardware_abstraction_R22',
      name: {
        en: 'Crypto Hardware Abtraction',
      },
      shortDescription: {
        en: 'Crypto Hardware Abstraction refers to a layer in the AUTOSAR Basic Software module Crypto Driver that sits above the actual hardware and provides a standardized interface for interacting with different cryptographic hardware devices.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_CryptoDriver_R22',
      name: {
        en: 'Crypto Driver',
      },
      shortDescription: {
        en: 'The Crypto Driver is a module in the AUTOSAR Basic Software that provides functionality, APIs, and configuration for cryptographic operations.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_CryptoServices_R22',
      name: {
        en: 'Crypto Service',
      },
      shortDescription: {
        en: 'The Crypto Service refers to a functionality provided by the automotive system that involves cryptographic operations, such as encryption and decryption, for securing data and communications within the system.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_hardware_abstraction_R22',
      name: {
        en: 'Hardware Abstraction',
      },
      shortDescription: {
        en: 'Hardware abstraction refers to the process of hiding the low-level details and complexities of hardware components and providing a simplified interface for software to interact with the hardware. In the context of automotive systems, the Hardware Abstraction Layer (HAL) provides a standardized interface between the software components and the underlying hardware.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_IO_driver_R22',
      name: {
        en: 'IO Driver',
      },
      shortDescription: {
        en: 'The IO Driver, also known as the DIO Driver, is a module in the AUTOSAR Basic Software that provides services for reading and writing to/from DIO Channels (Pins), DIO Ports, and DIO Channel Groups. It is specifically designed to work with on-chip DIO pins and ports.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_memory_driver_R22',
      name: {
        en: 'Memory Driver',
      },
      shortDescription: {
        en: 'Memory Driver is a software module that is responsible for managing the access and control of external memory devices in an automotive system.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_memory_hardware_abstraction_R22',
      name: {
        en: 'Memory Hardware Abstraction',
      },
      shortDescription: {
        en: 'Memory Hardware Abstraction is a concept that refers to the abstraction of the underlying memory hardware details and providing a standardized interface for accessing and managing memory in a system.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_MemoryServices_R22',
      name: {
        en: 'Memory Services',
      },
      shortDescription: {
        en: 'Memory Services refer to the set of functions and operations provided by the Memory Abstraction Interface (MemIf) module in the AUTOSAR Basic Software. The MemIf module acts as an abstraction layer between the NVRAM Manager (NvM) and the underlying memory abstraction modules, such as the Flash EEPROM Emulation (FEE) or EEPROM Abstraction (EA) modules.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_microcontroller_driver_R22',
      name: {
        en: 'Micro Controller Driver',
      },
      shortDescription: {
        en: 'The Micro Controller Unit (MCU) driver is a software module that provides services for the basic initialization and control of a microcontroller. It is part of the Microcontroller Abstraction Layer (MCAL) and is responsible for accessing the microcontroller hardware directly.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_OffBoardCommunication_R22',
      name: {
        en: 'OffBoard Communication',
      },
      shortDescription: {
        en: 'Off-board communication refers to the communication that takes place between a vehicle and external entities or systems. It involves the exchange of data and information between the vehicle and off-board services, such as cloud-based platforms, infrastructure, other vehicles, or pedestrians.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_onboard_device_abstraction_R22',
      name: {
        en: 'Onboard Device Abstraction',
      },
      shortDescription: {
        en: "Onboard Device Abstraction is a concept in the field of automotive software development, specifically within the context of AUTOSAR (AUTomotive Open System ARchitecture). It is a module that provides a standardized interface and functionality for accessing and managing various onboard devices in a vehicle's electronic control unit (ECU).",
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_Run_time_Environment_R22',
      name: {
        en: 'Run Time Environment',
      },
      shortDescription: {
        en: 'The Run-Time Environment (RTE) is a crucial component of the AUTOSAR (AUTomotive Open System ARchitecture) ECU (Electronic Control Unit) architecture. It serves as the realization of the interfaces of the AUTOSAR Virtual Function Bus (VFB) and provides infrastructure services for communication between Application Software Components. The RTE also facilitates access to basic software components, including the operating system (OS).',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_SystemServices_R22',
      name: {
        en: 'System Services',
      },
      shortDescription: {
        en: 'System services refer to a set of mechanisms or functions provided by the operating system (OS) or the Basic Software (BSW) modules in an automotive system. These services are designed to facilitate the optimal usage of a multi- core environment and ensure the smooth operation of the system.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
    {
      id: 'Autosar_wireless_communication_driver_R22',
      name: {
        en: 'Wireless communication Driver',
      },
      shortDescription: {
        en: 'The Wireless Ethernet driver is a module in the AUTOSAR Basic Software that provides communication access to the radio for wireless communications. Its main task is to provide a hardware-independent interface to the upper layer (Ethernet Interface) that comprises multiple equal controllers. This interface allows the upper layer to access the underlying bus system in a uniform manner.',
      },
      icon: 'document-copy-check',
      supportHistory: false,
      experimental: true,
      configurable: false,
      countTokens: false,
      external: false,
      config: {
        accessible: null,
        externalUrl: null,
        target: null,
        llms: [
          {
            id: 'GPT3',
            name: 'Microsoft | GPT-35-Turbo',
            supportStreaming: true,
            keyword: 'gpt3',
            enabled: true,
            tokenLimit: 4096,
            tokenThreshold: 3500,
            default: true,
          },
        ],
      },
      temperature: {
        defaultValue: true,
      },
    },
  ],
};
