// Enable/disable features here, can be set via env vars if necessary

import { MidasService } from '../src/services/midasService';

export const features = {
  byod: !MidasService.midasMode,
  useTestData: false,
  systemPrompt: process.env.GATSBY_FEATURE_SHOW_SYSTEM_PROMPT_GALLERY === 'true',
  promptGallery: process.env.GATSBY_FEATURE_SHOW_SYSTEM_PROMPT_GALLERY === 'true',
};
